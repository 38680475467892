import React, { Component } from "react";
import Heading from "../heading/heading";
import Filter from "../filter/filter";
import { connect } from "react-redux";
import ElasticSearchActions from "../../actions/elastic-search-actions";
import ManageAssignmentActions from "../../actions/manage-assignments-actions";
import MaintenanceTable from "../maintenance-table/maintenance-table";
import AssignmentMaintenanceTableMobile from "../assignment-maintenance-table-mobile/assignment-maintenance-table-mobile";
import apiClient from "../../actions/api-client";
import { RECEIVED_ASSIGNMENT_RESULTS, ActionIconTypes, ElasticSearchTypes, ElasticSearchColumns } from "../../actions/types";
import $ from "jquery";
import { Modal, Button, Alert } from "react-bootstrap";
import { ActionIcons } from "../actionIcons/action-Icons";
import validateInput from "../../validations/create-activity";
import { browserHistory } from "react-router";
import ProgressIndicator from "../progress-indicator/progress-indicator";
import config from "../../config";
import classnames from "classnames";
import ElasticSearchQuery from "../../actions/elastic-search-query";
import ReactScrollPagination from 'react-scroll-pagination'
import { Parser } from "html-to-react";
import ReactPDF from "react-pdf";
import ElasticSearchStore from "../../actions/elastic-search-store";
import moment from 'moment';
import TextFieldGroup from "../forms/text-field-group";
import CustomDatePicker from "../../components/datePicker/date-picker"
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { detect } from 'detect-browser';
import ClevertapReact from "clevertap-react";
import {
    parseHours,
    parseMinutes,
    parseSeconds
} from '../../utils/timeParser';
import ReactPlayer from 'react-player';
import googleIcon from '../login/btn_google_light_normal_ios.svg';
import interactiveIcon from '../../assets/images/interactive.png';

class Assignments extends Component {

    constructor(props) {
        super(props);
        // This binding is necessary to make `this` work in the callback
        this.openManageAssignmentPage = this.openManageAssignmentPage.bind(this);
        this.openViewStudentSubmissionsPage = this.openViewStudentSubmissionsPage.bind(this);
        this.openViewProgressPage = this.openViewProgressPage.bind(this);
        this.deleteAssignment = this.deleteAssignment.bind(this);
        this.getDeleteDialog = this.getDeleteDialog.bind(this);
        this.viewAssignmentOnRowClick = this.viewAssignmentOnRowClick.bind(this);
        this.manageAssignment = this.manageAssignment.bind(this);
        this.closeManageAssignment = this.closeManageAssignment.bind(this);
        this.studentDueDateChange = this.studentDueDateChange.bind(this);
        this.manageAssignStatusChange = this.manageAssignStatusChange.bind(this);
        this.updateAssignment = this.updateAssignment.bind(this);
        this.onClickCreateAssignmentOptions = this.onClickCreateAssignmentOptions.bind(this);
        this.getCreateActivityDialog = this.getCreateActivityDialog.bind(this);
        this.handleAlertDismiss = this.handleAlertDismiss.bind(this);
        this.getNextPage = this.getNextPage.bind(this);
        this.viewStudentProgress = this.viewStudentProgress.bind(this);
        this.syncReport = this.syncReport.bind(this);
        this.viewActivity = this.viewActivity.bind(this);
        this.showFullQuestion = this.showFullQuestion.bind(this);
        this.getActivityAssignDialog = this.getActivityAssignDialog.bind(this);
        this.assignActivity = this.assignActivity.bind(this);
        this.loadActivities = this.loadActivities.bind(this);
        this.searchActivities = this.searchActivities.bind(this);
        this.searchCourses = this.searchCourses.bind(this);
        this.sortByProgress = this.sortByProgress.bind(this);
        this.dialogueBox = this.dialogueBox.bind(this);
        this.deleteAllAssignments = this.deleteAllAssignments.bind(this);
        this.setTimeStampStart = this.setTimeStampStart.bind(this);
        this.setTimeStampEnd = this.setTimeStampEnd.bind(this);
        this.releaseReport = this.releaseReport.bind(this);
        // this.setLockAssignment = this.setLockAssignment.bind(this);
        this.allDeleteDialog = this.allDeleteDialog.bind(this);


        this.viewActivityCssClassName = "activityLogBlock questionActivityMainBox";
        this.tableCssClassName = 'listViewBlock ';
        this.filterComponentCssClassName = 'filterBlock NiceScroll';
        this.viewManagePanel = false;
        this.viewQuestionPanel = false;
        this.viewURLPanel = false;
        this.viewPDFPanel = false;
        this.viewVIDEOPanel = false;
        this.viewFlashcardsPanel = false;
        this.isShowFullQuestion = false;
        this.fullQuestion = null;
        this.isEditActivity = false;

        this.viewProgress = false;
        this.viewHelpText = false;

        // initial state for delete/duplicate dialog.
        this.state = {
            showDeleteDialog: false,
            deleteError: false,
            deleteErrorMessage: '',
            currentAssignment: null,

            duplicateDialogInput: '',
            showDuplicateDialog: false,
            duplicateError: false,
            duplicateErrorMessage: '',

            showCreateEditDialog: false,
            showSuccessDialog: false,
            taggedKeywordsHtml: [],
            taggedKeywords: [],
            taggedSubjectsHtml: [],
            taggedSubjects: [],
            allSubjects: [],
            createActivityTab: 1,
            activityID: "",
            activityType: "",
            activityName: "",
            externalURL: "",
            documentType: "",
            documentLink: "",
            keyName: "",
            base64: "",
            errors: {},
            showLearningFields: false,
            fileUpload: "",
            fileName: "",
            showAssignDialog: false,

            viewQuestionDetailsPage: false,
            indexNumberForQuestions: 0,
            loading: false,
            timeStampStart: null,
            timeStampEnd: null,
            lockAssignment: false,
            selectedRow: "",
            screenWidth: window.innerWidth,

            alertText: "This is a sample alert",
            alertStyle: "danger",
            showAlert: false,
            loadingNextPage: 0,
            showCreateAssignmentDialog: false,
            showAssignCourseDialog: false,
            showActivityAssignDialog: false,

            searchActivityName: '',
            activitiesSearchResult: [],

            searchCourseName: '',
            coursesSearchResult: [],

            getDataForReleaseReport: null,
            tempDate: "",
            dateVal: '',
            timeVal: '',
            dateVal2: '',
            timeVal2: '',
            checkVal: false,
            checkVal2: false
        };

        this.estore = new ElasticSearchStore();
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
        this.loadCourses();
        this.loadActivities();
    }


    dialogueBox() {

        let message = "All students that register to your class/classes after this point will automatically be assigned this assignment as long as this assignment is “open” (before due date)";

        return <Modal
            show={this.state.showClassAssignmentMessage}
            onHide={() => {

                this.props.location.state.showClassAssignmentMessage = false;
                this.setState({ showClassAssignmentMessage: false });

            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>

                <Modal.Title id="delete-activity-modal">You have successfully created "{this.state.Message}"</Modal.Title>

            </Modal.Header>
            <Modal.Body>
                <div className="mainFormBox">
                    <h3>{message}</h3>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showClassAssignmentMessage: false })
                }} bsStyle='primary'>Ok</Button>
            </Modal.Footer>
        </Modal>;

    }

    // setLockAssignment(e) {
    //     this.state.lockAssignment = e.target.checked
    // }

    setTimeStampStart(e) {

        if(e.target.value.includes('-'))
        {
            this.setState({dateVal: e.target.value});
            this.state.dateVal = e.target.value;

        }
        else if(e.target.value.includes(':'))
        {
            this.setState({timeVal: e.target.value});
            this.state.timeVal = e.target.value;

        }
        if(this.state.timeVal!='' && this.state.dateVal!='')
        {
        let fullDateObj = this.state.dateVal + 'T' + this.state.timeVal
        
        let timeStampSt = new Date(fullDateObj)
        
        this.setState({ timeStampStart: fullDateObj, checkVal:true })

        let assignmentDueDate = new Date(parseInt(this.state.currentAssignment.dueDate))
        let inputDate = moment((assignmentDueDate.getUTCMonth() + 1) + '/' + assignmentDueDate.getUTCDate() + '/' + assignmentDueDate.getUTCFullYear()).format('MM/DD/YYYY');

        var setTim = new Date(timeStampSt.getFullYear(), timeStampSt.getMonth(), timeStampSt.getDate(), 0, 0, 0);
        var setTimDueDate = new Date(inputDate)
        setTimDueDate = setTimDueDate && new Date(setTimDueDate.getFullYear(), setTimDueDate.getMonth(), setTimDueDate.getDate(), 0, 0, 0);
        if (setTimDueDate && setTimDueDate < setTim) {
            this.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: 'Please Enter "Available From" Date less than "Due Date"',
            });
            setTimeout(() => {
                this.setState({
                    showAlert: false,
                    alertText: '',
                });
            }, 5000);

            // this.state.timeStampStart = e.target.value
            return;
        }

        if (this.state.timeStampEnd && new Date(this.state.timeStampEnd) < new Date(fullDateObj)) {
            this.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: 'Please Enter "Available From" Date less than "Available Until"',
            });
            setTimeout(() => {
                this.setState({
                    showAlert: false,
                    alertText: ''
                });
            }, 5000);

            // this.state.timeStampStart = e.target.value
            return;
        }

    }

    }
    setTimeStampEnd(e) {

        if(e.target.value.includes('-'))
        {
            this.setState({dateVal2 :e.target.value});
            this.state.dateVal2 = e.target.value;
        }
        else if(e.target.value.includes(':'))
        {
            this.setState({timeVal2: e.target.value});
            this.state.timeVal2 = e.target.value;

        }
        if(this.state.timeVal2!='' && this.state.dateVal2!='')
        {
            let fullDateObj = this.state.dateVal2 + 'T' + this.state.timeVal2
        
        let timeStampSt = new Date(fullDateObj)
        this.state.timeStampEnd = fullDateObj
        this.state.checkVal2= true;

        let assignmentDueDate = new Date(parseInt(this.state.currentAssignment.dueDate))
        let inputDate = moment((assignmentDueDate.getUTCMonth() + 1) + '/' + assignmentDueDate.getUTCDate() + '/' + assignmentDueDate.getUTCFullYear()).format('MM/DD/YYYY');

        var setTim = new Date(timeStampSt.getFullYear(), timeStampSt.getMonth(), timeStampSt.getDate(), 0, 0, 0);
        var setTimDueDate = new Date(inputDate)
        setTimDueDate = setTimDueDate && new Date(setTimDueDate.getFullYear(), setTimDueDate.getMonth(), setTimDueDate.getDate(), 0, 0, 0);
        if (setTimDueDate && setTimDueDate > setTim) {
            this.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: 'Please Enter "Available Until" Date greater than "Due Date"',
            });
            setTimeout(() => {
                this.setState({
                    showAlert: false,
                    alertText: ''
                });
            }, 5000);

            // this.state.timeStampStart = e.target.value
            return;
        }

        if (this.state.timeStampStart && (new Date(this.state.timeStampStart) > new Date(e.target.value))) {
            this.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: 'Please Enter "Available From" Date less than "Available Until"',
            });
            setTimeout(() => {
                this.setState({
                    showAlert: false,
                    alertText: ''
                });
            }, 5000);

            // this.state.timeStampStart = e.target.value
            return;
        }


        }    }
    getNextPage() {

        const { screenWidth } = this.state;
        const isMobile = screenWidth <= 767;

        if (isMobile & this.state.loadingNextPage === 0) {
            let myID = Math.random() * 10000000000000000000;
            this.setState({ 'loadingNextPage': myID });
            console.log("getting more activities");
            if (this.state.loadingNextPage === myID) {
                let esAttr = this.props.elasticSearchAttributes.map.get('assignments');
                const { dispatch } = this.props;
                ElasticSearchActions.setElasticSearchAttributes(dispatch, 'assignments', esAttr.searchTerm, esAttr.pageSize + 50, esAttr.activePage, esAttr.sortObj, esAttr.filterValues);
                let elasticSearchQuery = new ElasticSearchQuery();
                elasticSearchQuery.query(ElasticSearchTypes.Assignment + config.stage, ElasticSearchColumns.Assignment, this.props,
                    esAttr.searchTerm, esAttr.pageSize + 50, esAttr.activePage, esAttr.sortObj, esAttr.filterValues, function (results, totalRecords) {
                        dispatch({
                            type: RECEIVED_ASSIGNMENT_RESULTS,
                            status: 200,
                            data: JSON.parse(JSON.stringify(results)),
                            alertText: '',
                            alertStyle: '',
                            showAlert: false,
                            totalRecords: totalRecords
                        });

                    }, true, 'originalOwner : ("' + this.props.auth.user.userName + '")');
                this.setState({ 'loadingNextPage': 0 });
            }
        }
    }

    componentDidMount() {
        const { dispatch } = this.props;
        ElasticSearchActions.setElasticSearchAttributes(dispatch, 'assignments', '', parseInt($('#pageSizeDropDown').val()) ? parseInt($('#pageSizeDropDown').val()) : 50, 1, { createdAt: { order: "desc" } }, []);
        let elasticSearchQuery = new ElasticSearchQuery();
        let queryString = 'originalOwner : ("' + this.props.auth.user.userName + '")';

        let that = this;
        that.showIndicator();
        this.setState({
            'alertText': this.props.manageAssignments.alertText,
            'alertStyle': this.props.manageAssignments.alertStyle,
            'showAlert': this.props.manageAssignments.showAlert
        });

        if (this.props.location.state) {
            this.setState({ ...this.props.location.state });

            if (this.props.location.state.assignmentFromDashboard) {
                this.openManageAssignmentPage(this.props.location.state.assignmentFromDashboard);

                elasticSearchQuery.query(ElasticSearchTypes.Assignment + config.stage, ElasticSearchColumns.Assignment, this.props,
                    this.props.location.state.assignmentFromDashboard.id ? this.props.location.state.assignmentFromDashboard.id.replace(/_/g, "") : this.props.location.state.assignmentFromDashboard.assignmentID.replace(/_/g, ""), 50, 1, { createdAt: { order: "desc" } }, [], function (results, totalRecords) {
                        dispatch({
                            type: RECEIVED_ASSIGNMENT_RESULTS,
                            status: 200,
                            data: JSON.parse(JSON.stringify(results)),
                            alertText: '',
                            alertStyle: '',
                            showAlert: false,
                            totalRecords: totalRecords
                        });
                        that.hideIndicator();
                    }, true, queryString);
            }
            else {
                elasticSearchQuery.query(ElasticSearchTypes.Assignment + config.stage, ElasticSearchColumns.Assignment, this.props,
                    '', parseInt($('#pageSizeDropDown').val()) ? parseInt($('#pageSizeDropDown').val()) : 50, 1, { createdAt: { order: "desc" } }, [], function (results, totalRecords) {
                        dispatch({
                            type: RECEIVED_ASSIGNMENT_RESULTS,
                            status: 200,
                            data: JSON.parse(JSON.stringify(results)),
                            alertText: '',
                            alertStyle: '',
                            showAlert: false,
                            totalRecords: totalRecords
                        });
                        that.hideIndicator();
                    }, true, queryString);
            }
            browserHistory.replace({
                pathname: '/assignments',
                state: {}
            });
        }
        else {
            elasticSearchQuery.query(ElasticSearchTypes.Assignment + config.stage, ElasticSearchColumns.Assignment, this.props,
                '', parseInt($('#pageSizeDropDown').val()) ? parseInt($('#pageSizeDropDown').val()) : 50, 1, { createdAt: { order: "desc" } }, [], function (results, totalRecords) {
                    dispatch({
                        type: RECEIVED_ASSIGNMENT_RESULTS,
                        status: 200,
                        data: JSON.parse(JSON.stringify(results)),
                        alertText: '',
                        alertStyle: '',
                        showAlert: false,
                        totalRecords: totalRecords
                    });
                    that.hideIndicator();
                }, true, queryString);
        }


        // Opening Help Text
        const browser = detect();
        if (browser.os.toLocaleLowerCase() !== 'ios') {
            // let selector = '#' + 'sidePanelContainer';
            // $(selector).addClass('DivShow');
            // $(selector).removeClass('questionActivityMainBox');
            //
            // this.viewActivityCssClassName = "activityLogBlock DivShow";
            // this.tableCssClassName = 'listViewBlock pulLeftClass';
            // this.filterComponentCssClassName = 'filterBlock NiceScroll collapsableDiv';
            // this.viewHelpText = true;
        }

    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    manageAssignViewToggle(evt) {

        $('#sidePanelContainer').toggleClass('manageAssignmentFullViewToggle');
        if ($('.manageAssignmentFullViewToggle').length > 0) {
            evt.target.innerHTML = 'Close Full Screen';
        } else {
            evt.target.innerHTML = 'View in Full  Screen';
        }
    }

    manageAssignment(assignment) {

        this.showIndicator();

        let params = {
            assignmentID: assignment.id ? assignment.id : assignment.assignmentID,
        };
        let pathTemplate = '/assignment/{assignmentID}';
        let method = 'GET';
        let additionalParams = {};
        let body = {};

        let context = this;
        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '2').then(function (result) {

            let selector = '#' + 'sidePanelContainer';
            $(selector).addClass('DivShow');
            $(selector).removeClass('questionActivityMainBox');
            $('#filterInnerContainer').addClass('collapsableDiv');
            $('#listViewMainTable').addClass('pulLeftClass');

            context.viewActivityCssClassName = "activityLogBlock DivShow";
            context.tableCssClassName = 'listViewBlock pulLeftClass';
            context.filterComponentCssClassName = 'filterBlock NiceScroll collapsableDiv';
            context.viewManagePanel = true;
            context.viewProgress = false;
            context.viewHelpText = false;
            context.viewFlashcardsPanel = false;
            context.viewQuestionPanel = false;
            context.viewURLPanel = false;
            context.viewPDFPanel = false;
            context.viewVIDEOPanel = false;
            context.isShowFullQuestion = false;

            context.setState({
                ...context.state,
                currentAssignment: result.data
            });

            context.hideIndicator();
        }).catch(function (result) {
            context.hideIndicator();
            console.error(result);
        });
    }

    handleWindowSizeChange = () => {
        this.setState({ screenWidth: window.innerWidth });
    };

    handleAlertDismiss(e) {
        this.setState({ 'showAlert': false });
    }

    showIndicator() {
        this.setState({
            ...this.state,
            loading: true
        });
    }

    hideIndicator() {
        this.setState({
            ...this.state,
            loading: false
        });
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onClassInputBlur(e) {

    }

    uploadFile(e) {
        this.setState({ 'fileUpload': e.target.files[0] });
        if (e.target.files[0]) {
            this.setState({ 'fileName': e.target.files[0].name });
        } else {
            this.setState({ 'fileName': '' });
        }
    }

    onActivityTypeSelect(e) {
        this.setState({ activityType: e.target.value });
        if (e.target.value === 'learning') {
            this.setState({ showLearningFields: true });
        } else {
            this.setState({ showLearningFields: false });
        }
    }

    getHtmlForViewActivity(message) {
        return (<div className="topRowHaedingAnch">
            <span className="scrollBar" />
            <div className="FrontQuestionBox">
                <div className="fixIconDiv">
                    <span className="cross" onClick={this.closeManageAssignment} />
                    <h3>View Activities</h3>
                    <div className="actionBlockDiv">
                        <h4>{message}</h4>
                    </div>
                </div>
            </div>
        </div>)
    }

    closeManageAssignment() {
        let selector = '#sidePanelContainer';
        $(selector).removeClass('DivShow');
        $(selector).addClass('questionActivityMainBox');
        $('#filterInnerContainer').removeClass('collapsableDiv');
        $('#listViewMainTable').removeClass('pulLeftClass');

        this.setState({ selectedRow: {}, timeStampStart: null, timeStampEnd: null, timeVal2:"",timeVal: "",dateVal:"", dateVal2:"", checkVal: false, checkVal2:false });
    }

    isValid() {
        const { errors, isValid } = validateInput(this.state, this.props.manageAssignments.allRecords, this.props.auth.user.userName);
        if (!isValid) {
            this.setState({ errors });
        }
        return isValid;
    }

    getSuccessDialog() {
        let message = "";
        if (this.isEditActivity) {
            message = "Activity have been updated successfully";
        }
        else {
            message = "Activity have been created successfully";
        }
        return <Modal
            show={this.state.showSuccessDialog}
            onHide={() => {
                this.setState({ showCreateEditDialog: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Success</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mainFormBox">
                    <h3>{message}</h3>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showSuccessDialog: false })
                }} bsStyle='primary'>Ok</Button>
            </Modal.Footer>
        </Modal>;
    }

    searchCourses(event) {
        let searchTerm = event.target.value.toLowerCase();
        let searchResult = [];
        let data = this.state.courses;
        if (searchTerm === "") {
            searchResult = data;
        }
        else {
            if (data.length > 0) {
                for (let i = 0; i < data.length; i++) {
                    let tempVarName = data[i].courseName.toLowerCase();
                    if (tempVarName.search(searchTerm) !== -1) {
                        searchResult.push(data[i]);
                    }
                }
            }
        }
        this.setState({ coursesSearchResult: searchResult, searchCourseName: searchTerm });
    }

    getAssignCourseDialog() {

        let courses = this.state.coursesSearchResult;
        let rows = null;
        let rowCount = 0;

        courses.forEach(row => {
            switch (row.subject && row.subject.toLowerCase()) {
                case 'algebra 1':
                case 'algebra':
                    row.className = 'subAlgebra';
                    break;
                case 'u.s. history and government':
                case 'us history':
                case 'u.s. history':
                    row.className = 'subUSHistory';
                    break;
                case 'global history and geography':
                    row.className = 'subGlobHistory';
                    break;
                case 'cc geometry':
                    row.className = 'subGeometry';
                    break;
                case 'earth science':
                    row.className = 'subErthSci';
                    break;
                case 'living environment':
                    row.className = 'subLivEnvi';
                    break;
                default:

                    break;
            }
        });

        if (courses) {
            rows = courses.map(course => {
                rowCount++;
                return <tr className={course.className} id={"row-" + rowCount} key={"row-" + rowCount}>
                    <td>
                        <span className="typeIcon" />
                        <span className="contentType ">
                            <h4>{course.courseName}</h4>
                            <p>By <strong>{course.ownerName}</strong></p>
                        </span>
                    </td>
                    <td>
                        <div title={course.subject}>{course.subject}</div>
                    </td>
                    <td>
                        <div className="tableActions">
                            <a href="javascript:void 0" onClick={function () {
                                this.assign(course);
                            }.bind(this)
                            }>Select</a>
                        </div>
                    </td>
                </tr>;
            })
        }
        let table = <table className="table table-hover hidden-xs">
            <thead>
                <tr>
                    <th>
                        Course Name
                    <span className="caret caret-close" />
                    </th>
                    <th>
                        Subject
                    <span className="caret caret-close" />
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>;

        return <Modal id="assign-dialog"
            backdrop="static"
            show={this.state.showAssignCourseDialog}
            onHide={() => {
                this.setState({ showAssignCourseDialog: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Assign</Modal.Title>
                <br />
                <br />
                <h5>Select course to assign. You can assign entire course or activities within the course.</h5>
            </Modal.Header>
            <Modal.Body>
                <div id="assign-content">
                    <TextFieldGroup
                        value={this.state.searchCourseName}
                        id="searchTextBoxCourse"
                        isDisabled={false}
                        field="searchCourses"
                        placeholder="Search Courses"
                        onChange={this.searchCourses}
                    />
                    {table}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showAssignCourseDialog: false });
                }}>Close</Button>
            </Modal.Footer>
        </Modal>
    }
    loadCourses() {
        return new Promise((resolve, reject) => {

            let context = this;
            let visibility = '(public OR "' + this.props.auth.user.userName + '"';
            if (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal") {
                visibility += " OR LPAdmin";
            }
            visibility += ')';
            let subjectsToSearch = "(";
            if (this.props.auth.user.subjects) {
                this.props.auth.user.subjects.forEach(sub => {
                    subjectsToSearch += "\"" + sub + "\" OR ";
                })
            }
            subjectsToSearch = subjectsToSearch.substring(0, subjectsToSearch.length - 3);
            let query = "visibleTo : " + visibility + " AND subject : " + subjectsToSearch + ")";
            this.estore.query(null, 'course' + config.stage, {
                "bool": {
                    "must": {
                        "query_string": {
                            "query": query
                        }
                    }
                }
            }, null, null, null, null, null).then((filterData) => {

                context.setState({
                    courses: filterData.data,
                    coursesSearchResult: filterData.data
                });
                resolve(filterData.data);
            }).catch(error => {
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to perform search, please contact administrator.',
                    loading: false
                });
                setTimeout(() => {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject(error);
            })
        })
    }

    assign(course) {

        this.showIndicator();
        let that = this;
        apiClient.invokeApi({ courseID: course.id }, '/course/{courseID}', 'GET', {}, {}).then(function (result) {
            that.hideIndicator();
            browserHistory.push({
                pathname: '/assignments/assign',
                state: { isCourseAssignment: true, course: result.data, isCourseLoaded: true, view: 'course' }
            });
        });
    }
    viewAssignmentOnRowClick(selectedRecord, event) {
        const { screenWidth } = this.state;
        const isTab = screenWidth <= 1199;
        if (isTab) {
            this.manageAssignment(selectedRecord);
        }
    }

    showViewDetailsPage() {
        this.setState({ viewQuestionDetailsPage: true, indexNumberForQuestions: 0 });

        let selector = '#' + 'tableFiltersAndSideView';
        $(selector).addClass('removeDisplay');
    }


    hideViewDetailsPage() {
        this.setState({ viewQuestionDetailsPage: false, indexNumberForQuestions: 0 });

        let selector = '#' + 'tableFiltersAndSideView';
        $(selector).removeClass('removeDisplay');
    }

    hideViewDetailsPageAndCloseViewActivity() {
        this.hideViewDetailsPage();
        this.closeManageAssignment();
    }

    openManageAssignmentPage(selectedRecord) {
        let that = this;
        this.setState({ selectedRow: selectedRecord }, function () {
            that.manageAssignment(selectedRecord);
        });
        console.log(selectedRecord);
    }

    openViewProgressPage(selectedRecord, isSubActivity, event) {

        this.setState({ selectedRow: selectedRecord });
        if (isSubActivity) {
            this.viewStudentProgress(selectedRecord, isSubActivity, event.target.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.dataset.parentId);
        }
        else {
            this.viewStudentProgress(selectedRecord, false, '');
        }

    }

    viewStudentProgress(assignmentOrActivity, isSubActivity, assignmentId) {
        this.showIndicator();


        let params = {
            assignmentID: assignmentOrActivity.id ? assignmentOrActivity.id : assignmentOrActivity.assignmentID,
        };
        let pathTemplate = '/assignment/progress/{assignmentID}';
        let serverlessFile = '2';
        if (isSubActivity) {
            pathTemplate = '/assignment/progress/{assignmentID}/activity/{activityID}';
            params = {
                assignmentID: assignmentId,
                activityID: assignmentOrActivity.activityID
            };
            serverlessFile = '3';
        }

        let context = this;
        apiClient.invokeApi(params, pathTemplate, 'GET', { 'accessToken': localStorage.getItem('accessToken') }, {}, serverlessFile).then(function (result) {
            let selector = '#' + 'sidePanelContainer';
            $(selector).addClass('DivShow');
            $(selector).removeClass('questionActivityMainBox');
            $('#filterInnerContainer').addClass('collapsableDiv');
            $('#listViewMainTable').addClass('pulLeftClass');

            context.viewActivityCssClassName = "activityLogBlock DivShow";
            context.tableCssClassName = 'listViewBlock pulLeftClass';
            context.filterComponentCssClassName = 'filterBlock NiceScroll collapsableDiv';
            context.viewManagePanel = false;
            context.viewFlashcardsPanel = false;
            context.viewQuestionPanel = false;
            context.viewURLPanel = false;
            context.viewPDFPanel = false;
            context.viewVIDEOPanel = false;
            context.isShowFullQuestion = false;
            context.viewProgress = true;
            context.viewHelpText = false;

            result.data.students.sort(function (a, b) {
                return /* String( */(a.firstName + a.lastName)/* ) */.localeCompare((b.firstName + b.lastName));
            });

            context.setState({
                ...context.state,
                currentAssignment: result.data
            });
            console.log("dcdd");
            context.hideIndicator();
        }).catch(function (result) {
            context.hideIndicator();
            console.error(result);
            console.log("error");
        });
    }

    syncReport(assignmentOrActivity) {

        let params = {};
        let body = assignmentOrActivity
        let pathTemplate = '/syncGoogleClassroomReport';
        let serverlessFile = '7';

        apiClient.invokeApi(params, pathTemplate, 'POST', { 'accessToken': localStorage.getItem('accessToken') }, body, serverlessFile)
        this.setState({
            showAlert: true,
            alertStyle: 'success',
            alertText: "Grades are being submitted to Google Classroom. This may take a few minutes"
        });

    }

    openViewStudentSubmissionsPage(selectedRecord) {
        let context = this;
        context.showIndicator();
        let params = {
            assignmentID: selectedRecord.id ? selectedRecord.id : selectedRecord.assignmentID,
        };
        let pathTemplate = '/assignment/progress/{assignmentID}';
        apiClient.invokeApi(params, pathTemplate, 'GET', {}, {}, '2').then(function (result) {
            context.hideIndicator();
            browserHistory.push({ pathname: '/assignments/submissions', state: { allStudents: result.data.students, assignment: selectedRecord } });
        }).catch(function (result) {
            context.hideIndicator();
            console.error(result);
        });
    }

    studentDueDateChange(evt) {
        let inputDate = moment((evt._d.getUTCMonth() + 1) + '/' + evt._d.getUTCDate() + '/' + evt._d.getUTCFullYear()).format('MM/DD/YYYY');
        let todayDate = moment().format('MM/DD/YYYY');
        if (new Date(inputDate) > new Date(todayDate)) {
            let timeStampSt = new Date(this.state.timeStampStart)
            var setTim = new Date(timeStampSt.getFullYear(), timeStampSt.getMonth(), timeStampSt.getDate(), 0, 0, 0);
            if (this.state.timeStampStart != "") {
                if (new Date(inputDate) < setTim) {
                    this.setState({
                        showAlert: true,
                        alertText: 'Please Enter "Due Date" greater than "Avaialble From" Date',
                        alertStyle: 'danger'
                    });
                    setTimeout(() => {
                        this.setState({
                            showAlert: false,
                            alertStyle: '',
                            alertText: ''
                        })
                    }, 5000);
                    return;
                }
            }
            let timeStampEn = new Date(this.state.timeStampEnd)
            var setTim2 = new Date(timeStampEn.getFullYear(), timeStampEn.getMonth(), timeStampEn.getDate(), 0, 0, 0);
            if (this.state.timeStampEnd != "") {
                if (new Date(inputDate) > setTim2) {
                    this.setState({
                        showAlert: true,
                        alertText: 'Please Enter "Due Date" less than "Avaialble Until" Date',
                        alertStyle: 'danger'
                    });
                    setTimeout(() => {
                        this.setState({
                            showAlert: false,
                            alertStyle: '',
                            alertText: ''
                        })
                    }, 5000);
                    return;
                }
            }
            let currentAssignment = this.state.currentAssignment;
            //currentAssignment.dueDate = new Date(evt.target.value).valueOf().toString();
            currentAssignment.dueDate = evt._d.toUTCString();
            this.setState({ tempDate: (inputDate) })
            this.setState({
                ...this.state,
                currentAssignment: currentAssignment
            })
        }
        else {
            this.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: 'Please provide "Due date" for a day in the future',
                loading: false
            });
            setTimeout(() => {
                this.setState({
                    showAlert: false,
                    alertStyle: '',
                    alertText: ''
                })
            }, 5000);
        }

    }

    manageAssignStatusChange(evt) {
        let currentAssignment = this.state.currentAssignment;
        currentAssignment.assignstatus = evt.target.value;
        this.setState({
            ...this.state,
            currentAssignment: currentAssignment
        })
    }

    updateAssignment() {
        this.showIndicator();
        console.log('Update assignment called.');

        let assignment = this.state.currentAssignment;
        let params = {
            assignmentID: assignment.assignmentID
        };
        let pathTemplate = '/assignment';
        let method = 'PUT';
        let additionalParams = { 'accessToken': localStorage.getItem('accessToken') };
        let body = assignment;
        if (this.state.timeStampEnd || this.state.timeStampStart) {

            if (!this.state.timeStampStart.includes('NaN') && (this.state.timeStampStart > this.state.timeStampEnd)) {
                this.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Please Enter "Available From" less than "Available Until"',
                    loading: false
                });
                setTimeout(() => {
                    this.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 5000);
                return;
            }
            if (this.state.timeStampStart && this.state.timeStampEnd  && (this.state.timeStampStart > this.state.timeStampEnd)) {
                this.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Please Enter "Available From" less than "Available Until"',
                    loading: false
                });
                setTimeout(() => {
                    this.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 5000);
                return;
            }

            if (this.state.timeStampStart != null) {
                var dateObj = new Date(this.state.timeStampStart);
                let assignmentDueDate = new Date(parseInt(this.state.currentAssignment.dueDate))
                let inputDate = moment((assignmentDueDate.getUTCMonth() + 1) + '/' + assignmentDueDate.getUTCDate() + '/' + assignmentDueDate.getUTCFullYear()).format('MM/DD/YYYY');
                var epochFormDueDate = new Date(inputDate)
                dateObj = new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate(), 0, 0, 0);
                var epochForm = Math.floor(dateObj);
                if (epochForm > Math.floor(epochFormDueDate)) {
                    this.setState({
                        showAlert: true,
                        alertStyle: 'danger',
                        alertText: 'Please Enter "Available From" less than "Due date"',
                        loading: false
                    });
                    setTimeout(() => {
                        this.setState({
                            showAlert: false,
                            alertStyle: '',
                            alertText: ''
                        })
                    }, 5000);
                    return;
                }
                dateObj = new Date(this.state.timeStampStart);
                epochForm = Math.floor(dateObj);
                if (body.assignedActivities) { body.assignedActivities.timeStampStart = epochForm }
                else if (body.assignedCourses) {
                    body.assignedCourses.timeStampStart = epochForm
                }
            }

            if (this.state.timeStampEnd != null) {
                var dateOb = new Date(this.state.timeStampEnd);
                var epoch = Math.floor(dateOb);
                let assignmentDueDate = new Date(body.dueDate).valueOf().toString();
                // let inputDate = moment((assignmentDueDate.getUTCMonth() + 1) + '/' + assignmentDueDate.getUTCDate() + '/' + assignmentDueDate.getUTCFullYear()).format('MM/DD/YYYY');
                var epochFormDueDate = parseInt(body.dueDate);
                let dueDa = new Date(body.dueDate).valueOf().toString();
                if (dueDa && dueDa !== 'NaN') {
                     epochFormDueDate = Math.floor(assignmentDueDate)
                }
                if (epoch < epochFormDueDate ) {
                    this.setState({
                        showAlert: true,
                        alertStyle: 'danger',
                        alertText: 'Please Enter "Available Until" greater than "Due date"',
                        loading: false
                    });
                    setTimeout(() => {
                        this.setState({
                            showAlert: false,
                            alertStyle: '',
                            alertText: ''
                        })
                    }, 5000);
                    return;
                }
                if (body.assignedActivities) { body.assignedActivities.timeStampEnd = epoch }
                else if (body.assignedCourses) {
                    body.assignedCourses.timeStampEnd = epoch
                }
            }
            if (body.assignedActivities) { body.assignedActivities.lockAssignment = true }
            else if (body.assignedCourses) {
                body.assignedCourses.lockAssignment = true
            }
        }

        if ((!this.state.dateVal.includes('NaN') && this.state.timeVal.includes('NaN')) ||(this.state.dateVal.includes('NaN') && !this.state.timeVal.includes('NaN'))) {
        
            this.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: 'Sorry, you have to enter both (Date and Time) of Avaialable From field',
                loading: false
            });
            setTimeout(() => {
                this.setState({
                    showAlert: false,
                    alertStyle: '',
                    alertText: ''
                })
            }, 5000);
            return;
        }
         if ((!this.state.dateVal2.includes('NaN') && this.state.timeVal2.includes('NaN')) ||(this.state.dateVal2.includes('NaN') && !this.state.timeVal2.includes('NaN'))) {
            this.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: 'Sorry, you have to enter both (Date and Time) of Avaialable Until field',
                loading: false
            });
            setTimeout(() => {
                this.setState({
                    showAlert: false,
                    alertStyle: '',
                    alertText: ''
                })
            }, 5000);
            return;
        }
        let dueDate = new Date(body.dueDate).valueOf().toString();
        if (dueDate && dueDate !== 'NaN') {
            body.dueDate = dueDate;
        }

        let context = this;
        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '2').then(function (result) {
            ClevertapReact.event("Update Assignment", { 'userName': context.props.auth.user.userName });
            context.setState({ currentAssignment: null, showAlert: true, alertText: result.data, alertStyle: 'success' });
            context.closeManageAssignment();
            setTimeout(() => {
                context.setState({ showAlert: false });
            }, 5000);
            context.hideIndicator();
            const { dispatch } = context.props;
            ElasticSearchActions.setElasticSearchAttributes(dispatch, 'assignments', '', parseInt($('#pageSizeDropDown').val()) ? parseInt($('#pageSizeDropDown').val()) : 50, 1, { createdAt: { order: "desc" } }, []);
            let elasticSearchQuery = new ElasticSearchQuery();
            setTimeout(() => {
                elasticSearchQuery.query(ElasticSearchTypes.Assignment + config.stage, ElasticSearchColumns.Assignment, context.props,
                    '', parseInt($('#pageSizeDropDown').val()) ? parseInt($('#pageSizeDropDown').val()) : 50, 1, { createdAt: { order: "desc" } }, [], function (results, totalRecords) {
                        dispatch({
                            type: RECEIVED_ASSIGNMENT_RESULTS,
                            status: 200,
                            data: JSON.parse(JSON.stringify(results)),
                            alertText: '',
                            alertStyle: '',
                            showAlert: false,
                            totalRecords: totalRecords
                        });
                    }, true, 'originalOwner : ("' + context.props.auth.user.userName + '")');
            }, 1000);
        }).catch(function (result) {
            console.error(result);
            context.setState({
                ...context.state,
                currentAssignment: null
            });
            context.closeManageAssignment();
        });
    }

    onClickCreateAssignmentOptions(event) {
        this.setState({
            view: event.target.value
        });
    }

    submitCreateAssignmentDialog() {
        switch (this.state.view) {
            case 'course':
                this.setState({
                    showAssignCourseDialog: true
                });
                //browserHistory.push({pathname: 'assignments/courses', state: {isFromAssignment:true}});
                break;
            case 'activity':
                this.setState({
                    showActivityAssignDialog: true
                });
                //browserHistory.push({pathname: 'assignments/activities', state: {isFromAssignmentToAssignActivity:true}});
                break;
            case 'newActivity':
                browserHistory.push({ pathname: 'assignments/activities', state: { isFromAssignment: true, showCreateEditDialog: true } });
                break;
        }

    }

    viewActivity(activityData) {
        let that = this;
        this.showIndicator();

        let params = {
            activityID: activityData.activityID,
        };
        let pathTemplate = '/activity/{activityID}';
        let method = 'GET';
        let additionalParams = {};
        let body = {};
        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
            let selector = '#' + 'sidePanelContainer';
            $(selector).addClass('DivShow');
            $(selector).removeClass('questionActivityMainBox');
            $('#filterInnerContainer').addClass('collapsableDiv');
            $('#listViewMainTable').addClass('pulLeftClass');

            that.viewActivityCssClassName = "activityLogBlock DivShow";
            that.tableCssClassName = 'listViewBlock pulLeftClass';
            that.filterComponentCssClassName = 'filterBlock NiceScroll collapsableDiv';
            that.viewManagePanel = false;
            that.viewProgress = false;
            that.viewFlashcardsPanel = false;
            that.viewQuestionPanel = false;
            that.viewURLPanel = false;
            that.viewPDFPanel = false;
            that.viewVIDEOPanel = false;
            that.isShowFullQuestion = false;
            that.viewHelpText = false;
            switch (result.data.activityType) {
                case 'flashcards':
                    that.viewFlashcardsPanel = true;
                    break;
                case 'question':
                    that.viewQuestionPanel = true;
                    break;
                case 'learning':
                    if (result.data.documentType === 'URL') {
                        that.viewURLPanel = true;
                    }
                    else if (result.data.documentType === 'PDF') {
                        that.viewPDFPanel = true;
                    } else if (result.data.documentType && result.data.documentType.toLowerCase() === 'video') {
                        that.viewVIDEOPanel = true;
                    }
                default:
                    break;
            }
            that.setState({
                ...that.state,
                viewActivityData: result.data
            });
            that.hideIndicator();
        }).catch(function (result) {
            that.hideIndicator();
            console.error(result);
        });
    }

    getCreateActivityDialog() {
        let body =
            <div id="assignment_choices">
                <h3><strong>Please select where you would like to assign activity/activities from...</strong></h3>
                <br />
                <ul>
                    <li>
                        <input id="course" type="radio" name="assignmentType" value="course" onClick={this.onClickCreateAssignmentOptions} />
                        <label htmlFor="course">Existing Course</label>
                    </li>
                    <li>
                        <input id="activity" type="radio" name="assignmentType" value="activity" onClick={this.onClickCreateAssignmentOptions} />
                        <label htmlFor="activity">Activity Listing</label>
                    </li>
                    <li>
                        <input id="newActivity" type="radio" name="assignmentType" value="newActivity" onClick={this.onClickCreateAssignmentOptions} />
                        <label htmlFor="newActivity">Create New Activity</label>
                    </li>
                </ul>
            </div>;

        return <Modal
            backdrop="static"
            show={this.state.showCreateAssignmentDialog}
            onHide={() => {
                this.setState({ showCreateAssignmentDialog: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Create Assignment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showCreateAssignmentDialog: false });
                    this.submitCreateAssignmentDialog();
                }} bsStyle='primary'
                    disabled={!this.state.view}>Next</Button>
            </Modal.Footer>
        </Modal>;
    }

    showFullQuestion(index, event) {
        let tempPropHolder = this.state.viewActivityData;
        let htmlToReactParser = new Parser();
        for (let i = 0; i < tempPropHolder.questions.length; i++) {
            if (index === i) {
                let quesText = "";
                let choicesText = "";
                let showChoices = true;
                if (tempPropHolder.questions[i] && tempPropHolder.questions[i].question) {
                    quesText = tempPropHolder.questions[i].question;
                    showChoices = quesText.indexOf('LP_ANSWERS') < 0;
                    if (tempPropHolder.questions[i].choices) {
                        for (let j = 1; j < tempPropHolder.questions[i].choices.length + 1; j++) {
                            if (tempPropHolder.questions[i].choices[j - 1] === tempPropHolder.questions[i].answer) {
                                choicesText += "<div class='fieldBox'><input type='radio' class='css-checkbox css-label1 bc_info card_side' value='choice" + j + "' name='choice" + j + "' checked/><label htmlFor='choice" + j + "' class='css-label radGroup1' >" + j + ") &nbsp; " + tempPropHolder.questions[i].choices[j - 1] + "</label></div>"
                            } else {
                                choicesText += "<div class='fieldBox'><input type='radio' class='css-checkbox css-label1 bc_info card_side' value='choice" + j + "' name='choice" + j + "' /><label htmlFor='choice" + j + "' class='css-label radGroup1' >" + j + ") &nbsp; " + tempPropHolder.questions[i].choices[j - 1] + "</label></div>"
                            }
                        }
                    }
                    quesText = quesText.replace('[[[LP_ANSWERS]]]', choicesText);
                }

                this.fullQuestion = <div className="innerQuestionBox NiceScroll" style={{ display: 'block' }}>
                    <h3> <span className="backArrow" onClick={() => { this.isShowFullQuestion = false; this.setState({ fullQuestionData: null }); }} /> Question {event.target.id}</h3>
                    <h4>Question:</h4>
                    <p dir lang={this.props.auth.user.prefLanguage}>{htmlToReactParser.parse(quesText)}</p>
                    {showChoices &&
                        <p dir lang={this.props.auth.user.prefLanguage}>{htmlToReactParser.parse(choicesText)}</p>
                    }
                    <h4>Hint</h4>
                    <p dir lang={this.props.auth.user.prefLanguage}>{htmlToReactParser.parse(tempPropHolder.questions[i].hint)}</p>
                    <h4>Explanation: </h4>
                    <p dir lang={this.props.auth.user.prefLanguage}>{htmlToReactParser.parse(tempPropHolder.questions[i].explanation.replace('autoplay', ''))}</p>

                </div>;
                break;
            }
        }
        let that = this;
        this.setState({ fullQuestionData: this.fullQuestion }, function () {
            that.isShowFullQuestion = true;
        });
    }

    loadActivities() {
        return new Promise((resolve, reject) => {

            let context = this;
            let visibility = '(public OR "' + this.props.auth.user.userName + '"';
            if (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal") {
                visibility += " OR LPAdmin";
            }
            visibility += ')';
            let subjectsToSearch = "(";
            if (this.props.auth.user.subjects) {
                this.props.auth.user.subjects.forEach(sub => {
                    subjectsToSearch += "\"" + sub + "\" OR ";
                })
            }
            subjectsToSearch = subjectsToSearch.substring(0, subjectsToSearch.length - 3);
            let query = "visibleTo : " + visibility + " AND subjects : " + subjectsToSearch + ")";
            this.estore.query(null, 'activity' + config.stage, {
                "bool": {
                    "must": {
                        "query_string": {
                            "query": query
                        }
                    }
                }
            }, [
                'id',
                'name',
                'subjects',
                'ownerName',
                'activityType'
            ], null, null, null, null).then((filterData) => {

                context.setState({
                    activities: filterData.data,
                    activitiesSearchResult: filterData.data
                });
                resolve(filterData.data);
            }).catch(error => {
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to perform search, please contact administrator.',
                    loading: false
                });
                setTimeout(() => {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject(error);
            })
        })
    }

    searchActivities(event) {
        let searchTerm = event.target.value.toLowerCase();
        let searchResult = [];
        let data = this.state.activities;
        if (searchTerm === "") {
            searchResult = data;
        }
        else {
            if (data.length > 0) {
                for (let i = 0; i < data.length; i++) {
                    let tempVarName = data[i].name.toLowerCase();
                    if (tempVarName.search(searchTerm) !== -1) {
                        searchResult.push(data[i]);
                    }
                }
            }
        }
        this.setState({ activitiesSearchResult: searchResult, searchActivityName: searchTerm });
    }

    getActivityAssignDialog() {

        let activities = this.state.activitiesSearchResult;
        let rows = null;
        let rowCount = 0;
        for (let q = 0; q < activities.length; q++) {

            if (activities[q].activityType) {
                switch (activities[q].activityType.toLowerCase()) {
                    case 'learning':
                        activities[q].className = 'learning';
                        break;
                    case 'flashcards':
                    case 'flashcard':
                        activities[q].className = 'flashCard';
                        break;
                    case 'question':
                        activities[q].className = 'questionRow';
                        break;
                    default:
                        activities[q].className = 'learning';
                        break;
                }
            }
        }
        if (activities) {

            rows = activities.map(activity => {
                rowCount++;
                return <tr className={activity.className} id={"row-" + rowCount} key={"row-" + rowCount}>
                    <td>
                        <span className="typeIcon" />
                        <span className="contentType ">
                            <h4 >{activity.name}</h4>
                            <p>By <strong>{activity.ownerName}</strong></p>
                        </span>
                    </td>
                    <td>
                        <div title={activity.subjects.toString()}>{activity.subjects.toString()}</div>
                    </td>
                    <td>
                        <div className="tableActions">
                            <a href="javascript:void 0" onClick={function () {
                                this.assignActivity(activity);
                            }.bind(this)
                            }>Assign</a>
                        </div>
                    </td>
                </tr>;
            })
        }
        let table = <table className="table table-hover hidden-xs">
            <thead>
                <tr>
                    <th>
                        Activity Name
                    <span className="caret caret-close" />
                    </th>
                    <th>
                        Subject
                    <span className="caret caret-close" />
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>;

        return <Modal id="assign-dialog"
            backdrop="static"
            show={this.state.showActivityAssignDialog}
            onHide={() => {
                this.setState({ showActivityAssignDialog: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Assign</Modal.Title>
                <br />
                <br />
                <h5>You can select activity to assign here, or  if you need to see activity details prior to assigning, you can assign activities from the <a href="/activities">Activity Tab</a></h5>
            </Modal.Header>
            <Modal.Body>
                <div id="assign-content">
                    <TextFieldGroup
                        value={this.state.searchActivityName}
                        id="searchTextBoxActivity"
                        isDisabled={false}
                        field="searchAcitivities"
                        placeholder="Search Activities"
                        onChange={this.searchActivities}
                    />
                    {table}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showActivityAssignDialog: false });
                }}>Close</Button>
            </Modal.Footer>
        </Modal>
    }

    assignActivity(activity) {

        this.showIndicator();
        let that = this;
        apiClient.invokeApi({ activityID: activity.id }, '/activity/{activityID}', 'GET', {}, {}).then(function (result) {
            that.hideIndicator();
            browserHistory.push({
                pathname: '/assignments/assign',
                state: { currentActivityObj: result.data, currentActivity: activity.id, view: 'activity' }
            });
        });
    }

    sortByProgress() {
        let ass = this.state.currentAssignment;
        if (this.state.progressSort && this.state.progressSort === 'asc') {
            ass.students.sort(function (a, b) {
                b.progress = b.progress ? b.progress : 0
                a.progress = a.progress ? a.progress : 0
                return (b.progress - a.progress);
            });
            this.setState({ currentAssignment: ass, progressSort: 'desc' });

        } else {
            ass.students.sort(function (a, b) {
                b.progress = b.progress ? b.progress : 0
                a.progress = a.progress ? a.progress : 0
                return (a.progress - b.progress);
            });
            this.setState({ currentAssignment: ass, progressSort: 'asc' });
        }

    }

    deleteAllAssignments() {

        let context = this;
        context.showIndicator();
        let params = {
            teacher: context.props.auth.user.userName,
        };
        let pathTemplate = '/DeleteAllAssignments/{teacher}';
        let method = 'DELETE';
        let additionalParams = {};
        let body = {};

        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '5').then((result) => {

            console.log(result)

            const { dispatch } = this.props;

            dispatch({
                type: RECEIVED_ASSIGNMENT_RESULTS,
                status: 200,
                data: JSON.parse(JSON.stringify([])),
                alertText: '',
                alertStyle: '',
                showAlert: false,
                totalRecords: 0,
                clearFilters: true
            });

            context.setState({
                showAlert: true,
                alertStyle: 'success',
                alertText: result.data
                // alertText: 'Unable to load data, please contact administrator.'
            }, context.hideIndicator);

        }).catch((error) => {

            let responseData = [];
            let combinedMessage = '';
            if (error.response && error.response.data.errors) {
                for (let err of error.response.data.errors)
                    responseData.push(err.message)

                combinedMessage = responseData.join(', ')
            }

            context.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: responseData.length != 0 && combinedMessage ? combinedMessage : error.message
                // alertText: 'Unable to load data, please contact administrator.'
            }, context.hideIndicator);
            // setTimeout(() => {
            //     context.setState({
            //         showAlert: false,
            //         alertStyle: '',
            //         alertText: ''
            //     })
            // }, 4000);
        });

    }

    releaseReport(selectedRecord) {
        if (selectedRecord && !selectedRecord.activityType) {
            selectedRecord = this.state.currentAssignment
        }
        this.showIndicator();
        let params = {
            assignmentID: selectedRecord.id ? selectedRecord.id : selectedRecord.assignmentID,
        };
        let pathTemplate = '/assignment/{assignmentID}';
        let method = 'GET';
        let additionalParams = {};
        let body = {};

        let context = this;
        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '2').then(function (result) {
            context.setState({
                ...context.state,
                getDataForReleaseReport: result.data
            });
        }).catch(function (result) {
            console.error(result);
        });

        setTimeout(() => {

            let assignment = this.state.getDataForReleaseReport;
            params = {
                assignmentID: assignment && assignment.assignmentID
            };
            pathTemplate = '/assignment';
            method = 'PUT';
            additionalParams = { 'accessToken': localStorage.getItem('accessToken') };
            body = assignment;
            if (body && body.assignedActivities) {
                body.assignedActivities.accessReport = true;
                body.assignedActivities.lockReport = false;
            }
            if (body && body.assignedCourses) {
                body.assignedCourses.accessReport = true;
            }


            assignment && apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '2').then(function (result) {

                context.setState({ currentAssignment: null, showAlert: true, alertText: "Report Released for this Assignment", alertStyle: 'success' });
                context.closeManageAssignment()
                context.hideIndicator();
                setTimeout(() => {
                    context.setState({ showAlert: false });
                    window.location.reload(false);

                }, 2500);
            }).catch(function (result) {
                console.error(result);
                context.setState({
                    ...context.state,
                    currentAssignment: null
                });
            });
        }, 4000);
    }
    render() {

        let tempAllResults = this.props.manageAssignments.results;
        let context = this;
        const { screenWidth } = this.state;
        const isMobile = screenWidth <= 768;
        for (let q = 0; q < tempAllResults.length; q++) {
            tempAllResults[q].assessmentType = tempAllResults[q].assignedActivities.M.assessmentType.S
            console.log(tempAllResults[q]);
            if (tempAllResults[q].activityType) {
                switch (tempAllResults[q].activityType.toLowerCase()) {
                    case 'learning':
                        tempAllResults[q].className = 'learning';
                        break;
                    case 'video':
                        tempAllResults[q].className = 'video';
                        break;
                    case 'flashcards':
                    case 'flashcard':
                        tempAllResults[q].className = 'flashCard';
                        break;
                    case 'question':
                        tempAllResults[q].className = 'questionRow';
                        break;
                    case 'course':
                        tempAllResults[q].className = 'booksRow';
                        break;
                    default:
                        tempAllResults[q].className = 'learning';
                        break;
                }
            }
            if (tempAllResults[q].id === this.state.selectedRow.id) {
                tempAllResults[q].className += " highlightRow";
            }
        }
        let esAttr = this.props.elasticSearchAttributes.map.get('assignments');

        let maintenanceTableData = {
            emptyMessage: this.props.manageAssignments.isFetching === false ? 'No records found.' : '',
            showActionMenu: true,
            actions: [
                {
                    linkLabel: 'Start Activity', action: (selectedRecord) => {
                        this.StartInteractiveAssignment(selectedRecord);
                    },
                    isConditional: true,
                    conditionExpression: (currentRecord) => {
                        console.log(currentRecord);
                        return (!isMobile 
                            && currentRecord.assessmentType === 'Interactive'
                            && currentRecord.assignstatus === 'Open'
                            && (currentRecord.originalOwner === this.props.auth.user.userName
                            || (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal")));
                    }
                },
                {
                    linkLabel: 'Manage', action: (selectedRecord) => {
                        this.openManageAssignmentPage(selectedRecord);
                    },
                    isConditional: true,
                    conditionExpression: (currentRecord) => {
                        return (!isMobile && (currentRecord.originalOwner === this.props.auth.user.userName
                            || (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal")
                            || currentRecord.originalOwner === this.props.auth.user.userName));
                    }
                },
                {
                    linkLabel: 'View Progress', action: (selectedRecord) => {
                        this.openViewProgressPage(selectedRecord, false)
                    },
                    isConditional: true,
                    conditionExpression: (currentRecord) => {

                        return (!isMobile && (currentRecord.originalOwner === this.props.auth.user.userName
                            || (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal")
                            || currentRecord.originalOwner === this.props.auth.user.userName));
                    }
                },
                {
                    linkLabel: 'Release Report', action: (selectedRecord) => {
                        this.releaseReport(selectedRecord)

                    },
                    isConditional: true,
                    conditionExpression: (currentRecord) => {

                        return (!isMobile && (currentRecord.originalOwner === this.props.auth.user.userName) && currentRecord.assignedActivities && currentRecord.assignedActivities.M && currentRecord.assignedActivities.M.accessReport && (!currentRecord.assignedActivities.M.accessReport.BOOL) && currentRecord.assignedActivities.M.lockReport && (currentRecord.assignedActivities.M.lockReport.BOOL));
                    }
                },
                {
                    linkLabel: 'View/Grade Essays', action: (selectedRecord) => {
                        context.openViewStudentSubmissionsPage(selectedRecord);
                    },
                    isConditional: true,
                    conditionExpression: (currentRecord) => {
                        return (!isMobile && (currentRecord.originalOwner === this.props.auth.user.userName
                            || (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal")
                            || currentRecord.originalOwner === this.props.auth.user.userName) && currentRecord.essayInReview);
                    }
                },
                {
                    linkLabel: 'Delete', action: (selectedRecord) => {
                        context.setState({ showDeleteDialog: true, record: selectedRecord });
                    },
                    isConditional: true,
                    conditionExpression: (currentRecord) => {
                        return (!isMobile && (currentRecord.originalOwner === this.props.auth.user.userName
                            || (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal")
                            || currentRecord.originalOwner === this.props.auth.user.userName));
                    }
                }
            ],
            columns: [
                {
                    name: 'assignmentName', type: 'text', label: 'Assignment Name', options: {
                        sortable: true, customDom: (record) => { // customDom is not applicable to array type attributes, ex: subjects in activity.
                            return (
                                <td width="42%">
                                    {record.assessmentType != 'Interactive' && <span className="typeIcon"> </span>}
                                    {record.assessmentType == 'Interactive' && <span className="typeIcon interactiveIcon"> 
                                        <img className="main-icon" src={interactiveIcon} />
                                        </span>}
                                    <span className="contentType ">
                                        <div style={{ fontStyle: 'bold', color: "#0f6fb4", 'width': '100%', 'white-space': 'nowrap', 'overflow': 'hidden', 'text-overflow': 'ellipsis' }} title={record.assignmentName}><strong>{record.assignmentName}</strong></div>
                                        <p><strong>{Math.round(parseInt(record.assignmentProgress))}% Complete</strong></p>
                                        {record.assignedActivities && record.assignedActivities.courseName && <p>Course: {record.assignedActivities.courseName["S"]}, Unit: {record.assignedActivities.unitName["S"]}</p>}
                                    </span>
                                    {record.activities && record.activities.length > 0 && <span className="subRowToggle" onClick={(evt) => {
                                        let parentRowId = evt.target.parentElement.parentElement.id;
                                        if (evt.target.getAttribute('class').trim().toLowerCase() === 'subRowToggle'.toLowerCase()) {
                                            $('#' + parentRowId + '-child').show();
                                            evt.target.setAttribute('class', 'subRowToggle minus');
                                        } else {
                                            $('#' + parentRowId + '-child').hide();
                                            evt.target.setAttribute('class', 'subRowToggle');
                                        }
                                    }} />}
                                    {record && record.assignedActivities && record.assignedActivities.M && (record.assignedActivities.M.lockReport) && record.assignedActivities.M.accessReport && <Button style={{ padding: "initial", fontSize: "14px", width: "95px", fontStretch: "condensed", marginLeft: "-3px" }} type="Button" onClick={() => { this.releaseReport(record) }} className="btn btn-primary" disabled={record.assignedActivities && record.assignedActivities.M && record.assignedActivities.M.accessReport && record.assignedActivities.M.accessReport.BOOL}>{record.assignedActivities && record.assignedActivities.M && record.assignedActivities.M.accessReport && record.assignedActivities.M.accessReport.BOOL ? "Released" : "Release Report"}</Button>}

                                </td>
                            );
                            // Above onClick handler must exist in order to expand collapse sub rows.
                            // TODO - remove Toggle text from above span once css is updated.
                        }
                    }
                },
                { name: 'periodName', type: 'text', label: 'Assigned To', options: { sortable: true, width: '12%' } },
                {
                    name: 'createdAt', type: 'date', label: 'Created On', options: {
                        sortable: true, customDom: (record) => { // customDom is not applicable to array type attributes, ex: subjects in activity.
                            if (record.createdAt !== 'NaN') {
                                let rawDate = new Date(parseFloat(record.createdAt));
                                let dueDate = (rawDate.getUTCMonth() + 1) + '/' + rawDate.getUTCDate() + '/' + rawDate.getUTCFullYear();
                                return (
                                    <td width="10%">
                                        {dueDate}
                                    </td>
                                );
                            }
                            else {
                                return (
                                    <td width="10%">
                                        NaN
                                    </td>
                                );
                            }

                        }
                    }
                },
                {
                    name: 'dueDate', type: 'date', label: 'Due Date', options: {
                        sortable: true, customDom: (record) => { // customDom is not applicable to array type attributes, ex: subjects in activity.
                            if (record.dueDate !== 'NaN') {
                                let rawDate = new Date(parseFloat(record.dueDate));
                                let dueDate = (rawDate.getUTCMonth() + 1) + '/' + rawDate.getUTCDate() + '/' + rawDate.getUTCFullYear();
                                return (
                                    <td width="10%">
                                        {dueDate}
                                    </td>
                                );
                            }
                            else {
                                return (
                                    <td width="10%">
                                        NaN
                                    </td>
                                );
                            }

                        }
                    }
                },
                {
                    name: 'availableUntil', type: 'date', label: 'Available Until', options: {
                        sortable: true, customDom: (record) => { // customDom is not applicable to array type attributes, ex: subjects in activity.
                            if (record.assignedActivities && record.assignedActivities.M && record.assignedActivities.M.timeStampEnd && record.assignedActivities.M.timeStampEnd.N) {
                                let raw = new Date(parseFloat(record.assignedActivities.M.timeStampEnd.N));
                                let aUntil = (raw.getUTCMonth() + 1) + '/' + raw.getDate() + '/' + raw.getUTCFullYear();
                                return (
                                    <td width="11%">
                                        {aUntil}
                                    </td>
                                );
                            }
                            else if (record.assignedCourses && record.assignedCourses.M && record.assignedCourses.M.timeStampEnd && record.assignedCourses.M.timeStampEnd.N) {
                                let raw = new Date(parseFloat(record.assignedCourses.M.timeStampEnd.N));
                                let aUntil = (raw.getUTCMonth() + 1) + '/' + raw.getDate() + '/' + raw.getUTCFullYear();
                                return (
                                    <td width="10%">
                                        {aUntil}
                                    </td>
                                );
                            }
                            else {
                                return (
                                    <td width="10%">
                                        {}
                                    </td>
                                );
                            }

                        }
                    }
                },
                {
                    name: 'assignstatus', type: 'text', label: 'Status', options: {
                        sortable: true, customDom: (record) => { // customDom is not applicable to array type attributes, ex: subjects in activity.
                            return (
                                <td width="10%">
                                    {record.assignstatus}
                                    {record.essayInReview && record.essayInReview.toLowerCase() === 'true' && <span className="reviewPend">Essays submitted waiting for teacher review</span>}

                                </td>
                            );
                        }
                    }
                },
            ],
            items: this.props.manageAssignments.results,
            rowExpansion: {     // There is no action support in expanded rows for now.
                showActionMenu: true,
                expansionConditionExpression: (currentRow) => {
                    // calculate condition to show expand button with row.
                    return currentRow.activities && currentRow.activities.length > 0;
                },
                getSubRowClass: (currentRow) => {
                    // calculate class for sub rows.
                    switch (currentRow.activityType.toLowerCase()) {
                        case 'learning':
                            return 'learning';
                        case 'flashcards':
                        case 'flashcard':
                            return 'flashCard';
                        case 'question':
                            return 'questionRow';
                        default:
                            return 'learning';
                    }
                },
                actionButtons: [],
                expansionKeyInCurrentItem: 'activities', // must be an array attribute in each record.
                expansionItemColumns: [                   // For now only text type is supported if its anything else it will convert it to text.
                    {
                        name: 'activityName', type: 'text', label: 'Activity Name', options: {
                            customDom: (record) => { // customDom is not applicable to array type attributes, ex: subjects in activity.
                                return (
                                    <td width="91%">
                                        <span className="typeIcon"> </span>
                                        <span className="contentType ">
                                            <div style={{ fontStyle: 'bold', color: "#0f6fb4", 'width': '100%', 'white-space': 'nowrap', 'overflow': 'hidden', 'text-overflow': 'ellipsis' }} title={record.activityName}><strong>{record.activityName}</strong></div>
                                            <p>Unit: {record.unitName}</p>
                                            {/*{(this.state.currentAssignment && this.state.currentAssignment.students && this.state.assignmentProgress>0  && this.state.currentActivityProgress > 0 ) && record.essayInReview &&*/}
                                            {/*{ this.state.currentAssignment.students.progress > 0 && */}



                                            {/*{(this.state.currentAssignment.students["0"].progress === 100 )&&*/}
                                            {record.activityInReview && record.activityInReview.toLowerCase() === 'true' && <span className="reviewPend">Essays submitted waiting for teacher review</span>}

                                        </span>
                                    </td>

                                );
                            }
                        }
                    },
                ],
                actions: [
                    {
                        linkLabel: 'View Activity', action: (selectedRecord) => {
                            this.viewActivity(selectedRecord);
                        }
                    },
                    {
                        linkLabel: 'View Progress', action: (selectedRecord, event) => {

                            this.openViewProgressPage(selectedRecord, true, event)
                        }
                    },/*
                                        {
                                            linkLabel: 'View Submission', action: (selectedRecord, event) => {

                                            this.openViewProgressPage(selectedRecord, true, event)
                                        }
                                        }

                                        */
                ],
                rowClickAction: (record) => {
                    console.log(record, 'Sub row clicked.');
                }
            },
            pageable: true,
            rowClickAction: this.viewAssignmentOnRowClick,
            totalRecords: this.props.manageAssignments.totalRecords,
            pagination: {
                pageSize: esAttr && esAttr.pageSize ? esAttr.pageSize : 50,
                activePage: 1,
                showPageInput: true,
                showFirstButton: true,
                showLastButton: true,
                showNextButton: true,
                showPreviousButton: true,
                firstButtonLabel: '<<',
                lastButtonLabel: '>>',
                previousButtonLabel: '<',
                nextButtonLabel: '>',
                showPageInfo: true,
                showGotoInput: false,
                gotoInputLabel: 'Goto',
                pageLinkSize: 5
            }
        };

        let assignmentName = this.state.currentAssignment && this.state.currentAssignment.assignmentName ? this.state.currentAssignment.assignmentName : '';
        let ownerName = this.state.currentAssignment && this.state.currentAssignment.ownerName ? this.state.currentAssignment.ownerName : '';
        let originalOwner = this.state.currentAssignment && this.state.currentAssignment.originalOwner ? this.state.currentAssignment.originalOwner : '';
        let courseName = this.state.currentAssignment && this.state.currentAssignment.assignedCourses ? this.state.currentAssignment.assignedCourses.courseName : '';
        let activityType = this.state.currentAssignment && this.state.currentAssignment.activityType ? this.state.currentAssignment.activityType : '';
        let periodName = this.state.currentAssignment && this.state.currentAssignment.periodName ? this.state.currentAssignment.periodName : '';
        let preserveOrder = this.state.currentAssignment && this.state.currentAssignment.preserveOrder ? this.state.currentAssignment.preserveOrder : '';
        let status = this.state.currentAssignment && this.state.currentAssignment.assignstatus ? this.state.currentAssignment.assignstatus : '';
        let dueDate = this.state.currentAssignment && this.state.currentAssignment.dueDate ? this.state.currentAssignment.dueDate : null;

        if (parseFloat(dueDate)) {
            dueDate = moment(moment.utc(parseFloat(dueDate)));
        }
        else {
            dueDate = moment(moment.utc(dueDate));
        }


        let assignedActs = [];
        let assignedUnits = [];

        if (this.state.currentAssignment && this.state.currentAssignment.assignedCourses && this.state.currentAssignment.assignedCourses.units) {
            this.state.currentAssignment.assignedCourses.units.forEach(unit => {
                assignedUnits.push(unit.unitName);
                unit.activities.forEach(act => {
                    assignedActs.push(act.activityName);
                });
            });
        }
        let sideViewPanel = null;



        if (this.viewProgress && this.state.currentAssignment && this.state.currentAssignment.students) {

            let allStudents = this.state.currentAssignment.students;
            let allStudents1 = this.state.currentAssignment.students["0"].progress;
            let allStudentsHtml = [];
            let completedCounter = 0;
            if (allStudents.length > 0) {
                allStudents.forEach(student => {

                    let status = 'Not started';
                    let className = 'text-danger';

                    if (student.progress) {
                        if (student.progress === 100) {
                            status = 'Completed';
                            className = 'text-success';
                            completedCounter++;
                        } else if (student.progress > 0) {
                            status = "In progress";
                            className = 'text-warning';
                        } else {
                            status = "Not started";
                            className = 'text-danger';
                        }
                    }
                    let timer = 'N/A';
                    if (student.timer) {
                        timer = parseHours(student.timer) + ':' +
                            parseMinutes(student.timer) + ':' +
                            parseSeconds(student.timer);
                    }
                    allStudentsHtml.push(
                        <tr>
                            <td>{student.firstName + " " + student.lastName}</td>
                            <td><span className={className}>{status}</span></td>
                            <td>{student.score ? student.score.toFixed(0) : 0}%</td>
                            <td>{timer ? timer : 'N/A'}</td>
                        </tr>
                    );
                });
            }
            const { screenWidth } = this.state;
            const isTab = screenWidth <= 1199;
            let record = this.state.currentAssignment
            sideViewPanel =
                <div className="learningSlideTab "><span className="cross" onClick={this.closeManageAssignment} />
                    <div className="FrontQuestionBox">
                        {isTab && <div><a href="#" onClick={() => { this.openManageAssignmentPage(this.state.currentAssignment) }}>Manage |</a><a href="#" onClick={() => { this.openViewProgressPage(this.state.currentAssignment, false) }}>Progress |</a><a href="#" onClick={() => { this.openViewStudentSubmissionsPage(this.state.currentAssignment) }}>Submission</a></div>}
                        <h3 style={{ marginTop: '10px' }}><strong>{assignmentName}</strong></h3> <a style={{ marginTop: '10px' }} href={"/reports/view/" + this.state.currentAssignment.assignmentID}>view details</a>
                        <h5>Assignment completed by: {completedCounter} out of {allStudents.length}</h5>
                        <div className="questionBlock NiceScroll">
                            <div className="respScrollTable">
                                <table className="table" style={{ border: '1px solid #ccc' }}>
                                    <thead>
                                        <tr>
                                            <th><p className="limitTextRes"><b>Students</b></p></th>
                                            <th><p className="limitTextRes" onClick={this.sortByProgress}><b>Progress</b><span className="caret caret-close"></span></p></th>
                                            <th><p className="limitTextRes"><b>Score</b></p></th>
                                            <th><p className="limitTextRes"><b>Time In</b></p></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allStudentsHtml}
                                    </tbody>
                                </table>
                            </div>
                            {/* {record && record.assignedActivities && (record.assignedActivities.M) && (record.assignedActivities.M.lockReport) && (record.assignedActivities.M.lockReport.BOOL) && <Button type="Button" onClick={this.releaseReport} className="btn btn-primary" disabled={record.assignedActivities && record.assignedActivities.accessReport}>{record.assignedActivities && record.assignedActivities.accessReport && record.assignedActivities.accessReport ? "Released" : "Release Report"}</Button>} */}
                        </div>
                    </div>
                </div>;
        }
        else if (this.viewManagePanel) {
            const { screenWidth } = this.state;
            const isTab = screenWidth <= 1199;
            let d = this.state.currentAssignment && ((this.state.currentAssignment.assignedActivities && new Date(this.state.currentAssignment.assignedActivities.timeStampStart)) || (this.state.currentAssignment.assignedCourses && new Date(this.state.currentAssignment.assignedCourses.timeStampStart)));
            let month = d && d.getMonth() + 1;
            if(!this.state.checkVal)this.state.timeStampStart = d && d.getFullYear() + "-" + (month < 10 ? '0' + month : month) + "-" + (d.getDate() < 10 ? '0' + d.getDate() : d.getDate()) + "T" + (d.getHours() < 10 ? '0' + d.getHours() : d.getHours()) + ":" + (d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes())
            let d2 = this.state.currentAssignment && ((this.state.currentAssignment.assignedActivities && new Date(this.state.currentAssignment.assignedActivities.timeStampEnd)) || (this.state.currentAssignment.assignedCourses && new Date(this.state.currentAssignment.assignedCourses.timeStampEnd)));
            let month1 = d2 && d2.getMonth() + 1;
            if(!this.state.checkVal2) this.state.timeStampEnd = d2 && d2.getFullYear() + "-" + (month1 < 10 ? '0' + month1 : month1) + "-" + (d2.getDate() < 10 ? '0' + d2.getDate() : d2.getDate()) + "T" + (d2.getHours() < 10 ? '0' + d2.getHours() : d2.getHours()) + ":" + (d2.getMinutes() < 10 ? '0' + d2.getMinutes() : d2.getMinutes())
            let splitTimeStampStart = this.state.timeStampStart && this.state.timeStampStart.split('T', 2)
            let splitTimeStampEnd = this.state.timeStampEnd && this.state.timeStampEnd.split('T', 2)
            if (this.state.timeStampStart ){
                let t = this.state.timeStampStart.split('T', 2);
                this.state.timeVal = t[1]
                this.state.dateVal = t[0]
            }
            if (this.state.timeStampEnd ){
                let t1 = this.state.timeStampEnd.split('T', 2);
                this.state.timeVal2 = t1[1]
                this.state.dateVal2 = t1[0]
            }
            sideViewPanel =
                <div className="learningSlideTab "><span className="cross" onClick={this.closeManageAssignment} />
                    <div className="FrontQuestionBox">
                        {isTab && <div><a href="#" onClick={() => { this.openManageAssignmentPage(this.state.currentAssignment) }}>Manage |</a><a href="#" onClick={() => { this.openViewProgressPage(this.state.currentAssignment, false) }}>Progress |</a><a href="#" onClick={() => { this.openViewStudentSubmissionsPage(this.state.currentAssignment) }}>Submission</a></div>}
                        <h3 style={{ marginTop: '10px' }}><strong>{assignmentName}</strong></h3>
                        <div className="actionBlockDiv">
                            {(ownerName === this.props.auth.user.userName
                                || originalOwner === this.props.auth.user.userName) &&
                                <ActionIcons iconType={ActionIconTypes.Delete} action={function () {
                                    context.setState({
                                        showDeleteDialog: true,
                                        record: { ...context.state.selectedRow, id: context.state.selectedRow.id }
                                    });
                                }} />
                            }
                        </div>
                        <div className="questionBlock NiceScroll">
                            <div>
                                <div id="assignmentData">
                                    <table>
                                        <tbody>
                                            {activityType && <tr>
                                                <td>
                                                    <strong>Assignment Type:</strong>
                                                </td>
                                                <td>
                                                    {activityType}
                                                </td>
                                            </tr>}
                                            {courseName && <tr>
                                                <td>
                                                    <strong>Assigned Course:</strong>
                                                </td>
                                                <td>
                                                    {courseName}
                                                </td>
                                            </tr>}
                                            {assignedUnits.length > 0 && <tr>
                                                <td>
                                                    <strong>Assigned Units:</strong>
                                                </td>
                                                <td>
                                                    {assignedUnits.toString()}
                                                </td>
                                            </tr>}
                                            {assignedActs.length > 0 && <tr>
                                                <td>
                                                    <strong>Assigned Activities:</strong>
                                                </td>
                                                <td>
                                                    {assignedActs.toString()}
                                                </td>
                                            </tr>}
                                            {periodName && <tr>
                                                <td>
                                                    <strong>Assigned To:</strong>
                                                </td>
                                                <td>
                                                    {periodName}
                                                </td>
                                            </tr>}
                                            {<tr>
                                                <td>
                                                    <strong>Lock Activity Order:</strong>
                                                </td>
                                                <td>
                                                    {preserveOrder === true ? 'Yes' : 'No'}
                                                </td>
                                            </tr>}
                                            <tr style={{ borderTop: '1px solid lightgray' }}>
                                                <td  >
                                                    <strong /*style={{paddingLeft: '10px', paddingRight: '5px'}}*/>Due Date:</strong>
                                                    {/*<input type="date" placeholder="Due date." value={dueDate} className="student_panel_due_date"*/}
                                                    {/*onChange={this.studentDueDateChange}/>*/}
                                                </td>

                                                <td className="dueDateSelector2">
                                                    <div className="kuchBhi"><DatePicker
                                                        customInput={<CustomDatePicker />}
                                                        placeholderText="Click to select a date"
                                                        selected={dueDate}
                                                        onChange={this.studentDueDateChange} />
                                                    </div>
                                                </td>
                                            </tr>

                                            {/* {this.state.currentAssignment && this.state.currentAssignment.activityType != "learning" && < tr style={{ borderTop: '1px solid lightgray' }}>
                                                <td  >
                                                    <strong style={{ fontSize: "12px" }} >Available From:</strong>
                                                    <br />
                                                    <strong style={{ marginLeft: "20px", fontSize: "12px" }}>Until:</strong>
                                                </td>

                                                <td>
                                                    <div className="kuchBhi">
                                                        <input type="time" onChange={this.setTimeStampStart}value={this.state.currentAssignment.assignedActivities ? this.state.currentAssignment.assignedActivities.timeStampStart : this.state.currentAssignment.assignedCourses.timeStampStart}/>
                                                        <input type="time" onChange={this.setTimeStampEnd} value={this.state.currentAssignment.assignedActivities ? this.state.currentAssignment.assignedActivities.timeStampEnd : this.state.currentAssignment.assignedCourses.timeStampEnd}/>
                                                    </div>
                                                </td>
                                            </tr>} */}
                                            {this.state.currentAssignment && this.state.currentAssignment.activityType != "learning" && < tr style={{ borderTop: '1px solid lightgray' }}>
                                                <td  >
                                                    <strong style={{ fontSize: "13px", fontStretch: "semi-condensed" }} >Available From:</strong>
                                                    <br />
                                                    <strong style={{ fontSize: "13px", fontStretch: "semi-condensed" }}>Available Until:</strong>
                                                </td>

                                                <td>
                                                    <div style={{marginLeft: "-22px"}} className="kuchBhi">
                                                        <input style={{width: "115px" ,fontSize: "12px"}} type="date" onChange={this.setTimeStampStart} value={this.state.dateVal !="" && this.state.dateVal } required={this.state.timeVal != "" && this.state.dateVal != "" && !this.state.timeVal.includes('NaN') && !this.state.dateVal.includes('NaN') ? true: false}/> <input style={{width: "99px"}} type="time" onChange={this.setTimeStampStart} value={this.state.timeVal !="" && this.state.timeVal} required={this.state.dateVal != "" && this.state.timeVal != "" && !this.state.timeVal.includes('NaN') && !this.state.dateVal.includes('NaN') ? true: false}/>
                                                    </div>
                                                        <div style={{marginLeft: "-22px"}} className="kuchBhi"><input style={{width: "115px", fontSize: "12px"}} type="date" onChange={this.setTimeStampEnd} value={this.state.dateVal2 !="" && this.state.dateVal2} required={this.state.timeVal2 != "" && this.state.dateVal2 != "" && !this.state.timeVal2.includes('NaN') && !this.state.dateVal2.includes('NaN') ? true: false}/> <input style={{width: "99px"}} type="time" onChange={this.setTimeStampEnd} value={this.state.timeVal2 !="" && this.state.timeVal2 } required={this.state.dateVal2 != "" && this.state.timeVal2 != "" && !this.state.timeVal2.includes('NaN') && !this.state.dateVal2.includes('NaN') ? true: false}/>
                                                        </div></td>
                                            </tr>}
                                            {status && <tr>
                                                <td>
                                                    <strong>Status:</strong>
                                                </td>
                                                <td>
                                                    {status}
                                                </td>
                                            </tr>}
                                        </tbody>
                                    </table>
                                    <br />
                                    {/* {this.state.currentAssignment && this.state.currentAssignment.activityType != "learning" && <div> <input type="checkbox" id="lock" className="css-checkbox" value={this.state.lockAssignment} onChange={this.setLockAssignment} />
                                        <label htmlFor="lock" className="css-label1" style={{ marginBottom: "15px" }}><b>Lock Assignment</b></label><br />
                                    </div>} */}
                                </div>
                            </div>
                        </div>
                        <Button onClick={this.updateAssignment} bsStyle='primary' style={{ float: 'right', marginTop: '10px', marginBottom: '60px' }}>Update Assignment</Button>
                    </div>
                </div >;
        }
        else if (this.viewHelpText) {
            sideViewPanel = <div className="topRowHaedingAnch">
                <span className="scrollBar" />
                <div className="FrontQuestionBox">
                    <div className="questionBlock NiceScroll">
                        <span className="cross" onClick={this.closeManageAssignment} />
                        <span className="typeIconsorn" />
                        <h1>Manage Assignments</h1>
                    </div>
                    <div style={{ marginTop: '30px' }}>
                        <div className="DropDivBlock" style={{ display: 'inline' }}>
                            <span className="bullets" />
                            <span className="bullets" />
                            <span className="bullets" />
                        </div>
                        <div className="listNumQuestion" style={{ marginLeft: '20px', display: 'inline' }}>
                            <h3 style={{ color: '#337ab7' }}>Manage your Assignments</h3>
                            <p style={{ margin: '0px 44px' }}>Manage due dates, status and cancel assignments</p>
                        </div>
                    </div>
                    <div style={{ marginTop: '7px' }}>
                        <div className="DropDivBlock" style={{ display: 'inline' }}>
                            <span className="bullets" />
                            <span className="bullets" />
                            <span className="bullets" />
                        </div>
                        <div className="listNumQuestion" style={{ marginLeft: '20px', display: 'inline' }}>
                            <h3 style={{ color: '#337ab7' }}>View Class Progress</h3>
                            <p style={{ margin: '0px 44px' }}>View class progress on assignments. For all activities within the assignment, view student progress, scores and time in activity</p>
                        </div>
                    </div>
                    <div style={{ marginTop: '7px' }}>
                        <div className="DropDivBlock" style={{ display: 'inline' }}>
                            <span className="bullets" />
                            <span className="bullets" />
                            <span className="bullets" />
                        </div>
                        <div className="listNumQuestion" style={{ marginLeft: '20px', display: 'inline' }}>
                            <h3 style={{ color: '#337ab7' }}>View/Grade Essays</h3>
                            <p style={{ margin: '0px 44px' }}>View and grade your students essays. "Waiting for review" notifies you there are essays waiting to be graded</p>
                        </div>
                    </div>
                </div>
            </div>
        }
        else if (this.viewQuestionPanel) {
            let allQuestions = [];
            let questionsHtmlArray = [];
            let tempPropHolder = this.state.viewActivityData;
            let parser = new DOMParser();
            for (let i = 0; i < tempPropHolder.questions.length; i++) {
                let question = tempPropHolder.questions[i];
                let questionToDisplay = question.question.replace('&nbsp;', '');
                let htmlToReactParser = new Parser();
                questionToDisplay = questionToDisplay.replace(/&nbsp;/g, " ");
                questionToDisplay = questionToDisplay.replace(/<br>/g, " ");
                questionToDisplay = questionToDisplay.replace(/<br\/>/g, " ");
                questionToDisplay = questionToDisplay.replace('[[[LP_ANSWERS]]]', '');
                questionToDisplay = questionToDisplay.replace(/<img .*?>/g, "");
                questionToDisplay = questionToDisplay.replace(/<p>/g, "");
                questionToDisplay = questionToDisplay.replace(/<\/p>/g, "");
                while (questionToDisplay.indexOf("size=\"4\"") >= 0) {
                    questionToDisplay = questionToDisplay.replace("size=\"4\"", "");
                }
                while (questionToDisplay.indexOf("size=\"3\"") >= 0) {
                    questionToDisplay = questionToDisplay.replace("size=\"3\"", "");
                }
                while (questionToDisplay.indexOf("size='4'") >= 0) {
                    questionToDisplay = questionToDisplay.replace("size='4'", "");
                }
                while (questionToDisplay.indexOf("size='3'") >= 0) {
                    questionToDisplay = questionToDisplay.replace("size='3'", "");
                }
                let text = htmlToReactParser.parse('<span>' + (i + 1) + ")  " + questionToDisplay + '</span>');

                questionsHtmlArray.push(
                    <div key={'question-' + i} className="listNumQuestion" onClick={(event) => {
                        this.showFullQuestion(i, event);
                    }}>
                        <p>{text}</p>
                        <span className="DirectionArrow" />
                    </div>
                );
            }
            let panel = null;
            if (this.isShowFullQuestion === false) {
                panel = <div className="FrontQuestionBox">
                    <div className="fixIconDiv">
                        <span className="cross" onClick={this.closeManageAssignment} />
                        <h3>{this.state.viewActivityData.activityName}</h3>
                    </div>
                    <div dir lang={this.props.auth.user.prefLanguage} className="questionBlock NiceScroll">
                        <h3 className="noteHeading">Question Activity</h3>
                        <p>Click on the question to view the explanation</p>
                        {questionsHtmlArray}
                    </div>
                </div>
            }
            else {
                panel = this.state.fullQuestionData;
            }
            sideViewPanel = <div className="topRowHaedingAnch">
                <span className="scrollBar" />
                {panel}
            </div>
        }
        else if (this.viewURLPanel) {
            sideViewPanel =
                <div className="learningSlideTab "><span className="cross" onClick={this.closeManageAssignment} />
                    <div className="FrontQuestionBox">
                        <h3>{this.state.viewActivityData.activityName}</h3>
                        <div className="questionBlock NiceScroll">
                            <h3 className="noteHeading">URL Activity</h3>
                            <div className="listNumQuestion">
                                <h6>Description</h6>
                                <p>{this.state.viewActivityData.description}</p>
                                <h4>URL</h4>
                                <a href={this.state.viewActivityData.documentLink}
                                    className="LinkLeaning"
                                    target="_blank">
                                    {this.state.viewActivityData.documentLink}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
        }

        else if (this.viewPDFPanel) {
            sideViewPanel =
                <div className="learningSlideTab "><span className="cross" onClick={this.closeManageAssignment} />
                    <div className="FrontQuestionBox">
                        <h3>{this.state.viewActivityData.activityName}</h3>
                        <div className="questionBlock NiceScroll">
                            <h3 className="noteHeading">PDF Activity</h3>
                            <div className="listNumQuestion">
                                <h4>PDF</h4>
                                <a href={this.state.viewActivityData.documentLink} target="_blank"
                                    className="LinkLeaning">
                                    {this.state.viewActivityData.fileName}
                                    <br />
                                    <ReactPDF
                                        file={this.state.viewActivityData.documentLink}
                                        scale={0.4}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
        }
        else if (this.viewVIDEOPanel) {
            sideViewPanel =
                <div className="learningSlideTab "><span className="cross" onClick={this.closeManageAssignment} />
                    <div className="FrontQuestionBox">
                        <h3>{this.state.viewActivityData.activityName}</h3>
                        <div className="questionBlock NiceScroll">
                            <h3 className="noteHeading">Video Activity</h3>
                            <div className="listNumQuestion">
                                <h4>Video</h4>
                                <a href={this.state.viewActivityData.documentLink} target="_blank"
                                    className="LinkLeaning">
                                    {this.state.viewActivityData.fileName}
                                    <br />
                                    <ReactPlayer width="340px" height="auto"
                                        playing={false}
                                        url={this.state.viewActivityData.documentLink}
                                        controls={true}
                                        scale={0.4}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
        }

        else if (this.viewFlashcardsPanel) {
            let flashcardsArray = [];
            let tempPropHolder = this.state.viewActivityData;
            for (let j = 0; j < tempPropHolder.words.length; j++) {
                flashcardsArray.push(
                    <div key={'question-' + j} className="listNumQuestion">
                        <h4>{tempPropHolder.words[j].translatedWord ? tempPropHolder.words[j].translatedWord : tempPropHolder.words[j].word}</h4>
                        <p >{tempPropHolder.words[j].definition}</p>
                    </div>
                );
            }
            sideViewPanel = <div className="topRowHaedingAnch">
                <span className="scrollBar" />
                <div className="FrontQuestionBox">
                    <div className="fixIconDiv">
                        <span className="cross" onClick={this.closeManageAssignment} />
                        <h3>{this.state.viewActivityData.activityName}</h3>
                    </div>
                    <div className="questionBlock NiceScroll">
                        <h3 className="noteHeading">Flashcard Activity</h3>
                        {flashcardsArray}
                    </div>
                </div>
            </div>
        }

        let viewQuestionDetailsPage = null;


        let hideHeading = this.state.viewQuestionDetailsPage;

        let styleForMobileVIew = {};
        if (isMobile) {
            styleForMobileVIew = { "width": "100%", "height": "100%" };
            if (this.viewManagePanel || this.viewQuestionPanel || this.viewURLPanel || this.viewPDFPanel || this.viewVIDEOPanel || this.isShowFullQuestion || this.viewFlashcardsPanel || this.state.viewQuestionDetailsPage) {
                hideHeading = true;
            }
        }
        return (
            <div>
                <Heading
                    location="assignments"
                    actionName={RECEIVED_ASSIGNMENT_RESULTS}
                    isHideHeading={hideHeading}
                    allButtons={[
                        <button type="button" className="btn btn-primary crtBtn" onClick={() => { this.setState({ showCreateAssignmentDialog: true }) }}
                            key="CreateButton"><span> </span>Create New</button>
                    ]}
                    deleteButtons={[
                        <button type="button" className="btn btn-primary delete" style={{ position: "absolute" }} onClick={() => {
                            this.setState({ showAllDeleteDialog: true })
                        }}
                            key="DeleteButton"><span> </span>Delete All</button>
                    ]}
                    syncButtons={[
                        <div className="SyncBtnDiv"><button type="button" id="googleSync" className="googleSynBtn" onClick={() => { this.syncReport(tempAllResults) }}
                            key="SyncButton"><img className="googleSyncImg" src={googleIcon} /><span className="syncBtnText">Submit Grades</span></button></div>
                    ]}
                    isCreateButton={false}
                    isDeleteButton={true}
                    isSyncButton={true}
                    heading="Manage Assignments"
                    isShowButton={true}
                    isSearch={true}
                    screenNameForElasticSearch={ElasticSearchTypes.Assignment}
                    allColumnsForElasticSearch={ElasticSearchColumns.Assignment}
                    customQuery={'originalOwner : ("' + this.props.auth.user.userName + '")'}
                // backColor="#ccffcc"
                />
                {this.state.showAlert &&
                    <div className="row">
                        <div className="col-md-3 col-xs-3" />
                        <div className="col-md-6 col-xs-3">
                            <Alert bsStyle={this.state.alertStyle}
                                onDismiss={this.handleAlertDismiss}>
                                {this.state.alertText}
                            </Alert>
                        </div>
                        <div className="col-md-3 col-xs-3" />
                    </div>
                }
                <div id="tableFiltersAndSideView">
                    <div className={classnames("container-fluid noPad listMainBoxWrapper", { "removeDisplay": hideHeading })}>
                        <Filter
                            customQuery={'originalOwner : ("' + this.props.auth.user.userName + '")'}
                            location="assignments"
                            viewActivityCssClass={this.filterComponentCssClassName}
                            closeViewActivity={this.closeManageAssignment}
                            clearFilters={'manageAssignments.clearFilters'}
                            actionName={RECEIVED_ASSIGNMENT_RESULTS}
                            filterColumns={[
                                { name: 'periodName', type: 'text', label: 'Class' },
                                { name: 'assignstatus', type: 'text', label: 'Status' },
                                { name: 'desiredMonth', type: 'text', label: 'Month' }
                            ]}
                            screenNameForElasticSearch={ElasticSearchTypes.Assignment}
                            allColumnsForElasticSearch={ElasticSearchColumns.Assignment}
                        />
                        <div className={this.tableCssClassName} id="listViewMainTable">
                            <div className="listToBesslide NiceScroll  ">
                                <div className="blockForlist ">
                                    <MaintenanceTable
                                        customQuery={'originalOwner : ("' + this.props.auth.user.userName + '")'}
                                        location="assignments"
                                        actionName={RECEIVED_ASSIGNMENT_RESULTS}
                                        screenNameForElasticSearch={ElasticSearchTypes.Assignment}
                                        allColumnsForElasticSearch={ElasticSearchColumns.Assignment}
                                        options={maintenanceTableData}
                                        sortAction={{
                                            action: RECEIVED_ASSIGNMENT_RESULTS,
                                            attributes: {
                                                skipAlterForAllRecords: true
                                            },
                                            dataPath: 'data'
                                        }}
                                    />
                                    <AssignmentMaintenanceTableMobile items={this.props.manageAssignments.results}
                                        emptyMessage={maintenanceTableData.emptyMessage}
                                        columns={maintenanceTableData.columns}
                                        viewActivityMobile={this.openManageAssignmentPage}
                                    />
                                    <ReactScrollPagination
                                        fetchFunc={this.getNextPage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={this.viewActivityCssClassName} id='sidePanelContainer' style={styleForMobileVIew}>
                        {sideViewPanel}
                    </div>
                </div>
                <div>
                    {viewQuestionDetailsPage}
                </div>
                {this.getDeleteDialog()}
                {this.allDeleteDialog()}
                {this.state.showAssignCourseDialog && this.getAssignCourseDialog()}
                {this.props.location.state && this.state.showClassAssignmentMessage && this.dialogueBox()}
                {this.state.showActivityAssignDialog && this.getActivityAssignDialog()}
                <ProgressIndicator show={this.state.loading} />
                {this.getCreateActivityDialog()}
            </div>
        );
    }

    searchBarChangeHandler() {
        const { dispatch } = this.props;
        if (document.getElementById("searchBar").value) {
            let opts = {
                "match": {
                    "keywords": document.getElementById("searchBar").value
                }
            };
            ElasticSearchActions.query(dispatch, 'assignments', opts);
        } else {
            ElasticSearchActions.query(dispatch);
        }
    }

    getDeleteDialog(selectedRecord) {
        let body = <div id="deleteDialogContent">
            <p>
                Are you sure you want to delete the assignment? All reporting data will be lost.
            </p>
            {this.state.deleteError ? <Alert bsStyle="danger">
                {this.state.deleteErrorMessage}
            </Alert> : ''}
        </div>;
        return <Modal
            backdrop="static"
            show={this.state.showDeleteDialog}
            onHide={() => {
                this.setState({ showDeleteDialog: false, record: null, deleteError: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Delete Assignment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showDeleteDialog: false, record: null, deleteError: false });
                }}>Cancel</Button>
                <Button onClick={() => {
                    this.deleteAssignment(this.state.record).then(result => {
                        this.clearFilters();
                        this.setState({ showDeleteDialog: false, record: null, deleteError: false });
                        this.hideViewDetailsPageAndCloseViewActivity();
                        const { dispatch } = this.props;
                        ElasticSearchActions.setElasticSearchAttributes(dispatch, 'assignments', '', parseInt($('#pageSizeDropDown').val()) ? parseInt($('#pageSizeDropDown').val()) : 50, 1, { createdAt: { order: "desc" } }, []);
                        let elasticSearchQuery = new ElasticSearchQuery();
                        setTimeout(() => {
                            elasticSearchQuery.query(ElasticSearchTypes.Assignment + config.stage, ElasticSearchColumns.Assignment, this.props,
                                '', parseInt($('#pageSizeDropDown').val()) ? parseInt($('#pageSizeDropDown').val()) : 50, 1, { createdAt: { order: "desc" } }, [], function (results, totalRecords) {
                                    dispatch({
                                        type: RECEIVED_ASSIGNMENT_RESULTS,
                                        status: 200,
                                        data: JSON.parse(JSON.stringify(results)),
                                        totalRecords: totalRecords
                                    });
                                }, true, 'originalOwner : ("' + this.props.auth.user.userName + '")');
                        }, 1000);
                    }).catch(error => {
                        this.setState({ deleteError: true, deleteErrorMessage: error.statusMessage });
                    });
                }} bsStyle='primary'>Delete</Button>
            </Modal.Footer>
        </Modal>;
    }

    allDeleteDialog() {
        let body = <div id="deleteDialogContent">
            <p>
                Are you sure you want to delete all the assignments? All reporting data will be lost.
            </p>
            {/* {this.state.deleteError ? <Alert bsStyle="danger">
                {this.state.deleteErrorMessage}
            </Alert> : ''} */}
        </div>;
        return <Modal
            backdrop="static"
            show={this.state.showAllDeleteDialog}
            onHide={() => {
                this.setState({ showAllDeleteDialog: false/*, deleteError: false*/ });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Delete All Assignments</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button style={{ marginRight: "5px" }} onClick={() => {
                    this.setState({ showAllDeleteDialog: false/*, deleteError: false*/ });
                }}>Cancel</Button>
                <Button onClick={() => {
                    this.setState({ showAllDeleteDialog: false/*, deleteError: false*/ }, () => {
                        this.deleteAllAssignments()
                    });
                    // this.setState({deleteError: true, deleteErrorMessage: error.statusMessage});
                }} bsStyle='danger'>Delete</Button>
            </Modal.Footer>
        </Modal>;
    }

    deleteAssignment(selectedRecord) {
        let rejectDuplicate = (reject, error) => reject({
            status: 400,
            statusMessage: error.message
        });
        return new Promise((resolve, reject) => {
            this.showIndicator();
            let params = {
                assignmentID: selectedRecord.id,
                ownerName: this.props.auth.user.userName
            };
            let pathTemplate = '/assignment/{assignmentID}/ownername/{ownerName}';
            let method = 'DELETE';
            let that = this;
            apiClient.invokeApi(params, pathTemplate, method, { 'accessToken': localStorage.getItem('accessToken') }, {}).then(function (result) {
                ClevertapReact.event("Delete Assignment", { 'userName': that.props.auth.user.userName });
                that.setState({ showAlert: true, alertText: result.data, alertStyle: "success" });
                setTimeout(() => {
                    that.setState({ ...that.state, showAlert: false });
                }, 5000);
                that.hideIndicator();
                resolve({
                    status: result.status,
                    statusMessage: result.data
                });
            }).catch(function (result) {
                that.setState({ showAlert: true, alertText: result.message, alertStyle: "danger" });
                setTimeout(() => {
                    that.setState({ ...that.state, showAlert: false });
                }, 5000);
                that.hideIndicator();
                rejectDuplicate(reject, { message: 'Internal error.' });
            });
        });
    }


    clearFilters() {
        setTimeout(() => {
            let selectedFilters = $('#tableFiltersAndSideView')[0];
            selectedFilters = [...selectedFilters.querySelectorAll('input[type=checkbox]:checked')];
            for (var i = 0; i < selectedFilters.length; i++) {
                selectedFilters[i].checked = false
            }
        }, 1500);
    }

    StartInteractiveAssignment(assignment){
        //    browserHistory.push({pathname: 'assignments/interactive', state: {isFromAssignment:true}});
       browserHistory.push({pathname: 'assignments/interactive/'+assignment.id+'/'+assignment.assignmentName});
    }
}
function mapStateToProps(state) {
    return { ...state };
}
export default connect(mapStateToProps)(Assignments);
